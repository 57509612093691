<template>
    <div class="time-line-img-box" :class="{'major-col': majorValue}">
      <div class="img-title">{{infoName}}</div>
<!--      <img :src="require('@/assets/images/TimeLineImg/'+ Number(LineNumber) + '.jpg')" alt="知了写作">-->
      <div class="steps-box">
        <el-steps direction="vertical" :active="LineNumber">
          <el-step>
            <template slot="description">
              <div class="steps-item" :class="{'box-s':LineNumber === 1}">
                <div class="title">输入标题、专业，点击立即生成</div>
                <div class="cont">
                  <div class="lv-box lv-box-1">
                    <div class="lv1-title">请输入{{infoName==='订单查询'?'论文':infoName==='读后感'?'书名':infoName}}{{infoName==='读后感'?'':'标题'}}</div>
                    <div class="lv1-cont">
                      <div class="input">{{infoName==='读后感'?'书名':'标题内容'}}</div>
                      <div class="btn">立即生成</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="steps-icon">
                <i class="el-icon-d-arrow-left"></i>
              </div>
            </template>
          </el-step>
          <el-step>
            <template slot="description">
              <div class="steps-item" :class="{'box-s':LineNumber === 2}">
                <div class="title">支付宝、微信扫码支付</div>
                <div class="cont">
                  <div class="lv-box lv-box-2">
                    <div class="lv2-title">
                      <img src="@/assets/images/icon/order.png" alt="">
                      <span>支付订单</span>
                    </div>
                    <div class="lv2-cont">
                      <div class="left-ewm">
                        <svg class="icon" width="34" height="34" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                          <path d="M568.889116 0l0 455.110884 455.108837 0L1023.997953 0 568.889116 0zM967.109349 398.222279l-341.333674 0L625.775674 56.888605l341.333674 0L967.109349 398.222279zM113.778233 341.332651l227.555442 0L341.333674 113.777209 113.778233 113.777209 113.778233 341.332651zM0.001023 1023.998977l455.110884 0L455.111907 568.888093 0.001023 568.888093 0.001023 1023.998977zM56.889628 625.777721l341.333674 0 0 341.333674L56.889628 967.111395 56.889628 625.777721zM910.220744 113.777209 682.665302 113.777209l0 227.555442 227.555442 0L910.220744 113.777209zM0.001023 455.110884l455.110884 0L455.111907 0 0.001023 0 0.001023 455.110884zM56.889628 56.888605l341.333674 0 0 341.333674L56.889628 398.222279 56.889628 56.888605zM910.220744 1023.998977l113.778233 0L1023.998977 796.443535 910.220744 796.443535 910.220744 1023.998977zM910.220744 682.665302l113.778233 0L1023.998977 568.888093 910.220744 568.888093 910.220744 682.665302zM113.778233 910.220744l227.555442 0L341.333674 682.665302 113.778233 682.665302 113.778233 910.220744zM739.553907 568.888093 568.889116 568.888093l0 170.666837 170.664791 0 0 56.888605 113.778233 0L853.332139 682.665302 739.553907 682.665302 739.553907 568.888093zM568.889116 1023.998977 796.442512 1023.998977 796.442512 910.220744 568.889116 910.220744 568.889116 1023.998977z" fill="#3c3c3c"></path>
                        </svg>
                        <i class="el-icon-full-screen ewm-icon"></i>
                      </div>
                      <div class="right-text">
                        <div class="t">{{infoName==='订单查询'?'论文':infoName}} - {{majorValue?'专业版':'标准版'}}: 单价：**元/篇</div>
                        <div class="m">总金额：<span>** 元</span></div>
                        <div class="f">
                          <img src="@/assets/images/icon/zfb-pay2.png" alt="">
                          <img src="@/assets/images/icon/wx-pay2.png" alt="">
                          <span>使用微信、支付宝扫码支付</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="steps-icon">
                <i class="el-icon-d-arrow-left"></i>
              </div>
            </template>
          </el-step>
          <el-step>
            <template slot="description">
              <div class="steps-item" :class="{'box-s':LineNumber === 3}">
                <div class="title">等待 AI 生成</div>
                <div class="cont">
                  <div class="lv-box-3">
                    <div class="lv3-left">
                      <div class="lv3-title">
                        <div class="l">
                          <img src="@/assets/images/icon/sxbg.png" alt="">
                          <span>标题</span>
                        </div>
                        <div class="r">
                          <span>{{ infoName==='订单查询'?'论文':infoName }}</span>
                          <span>{{ majorValue?'专业版':'标准版' }}</span>
                        </div>
                      </div>
                      <div class="lv3-right">
                        <span>下单时间: ****-**-**</span>
                        <span class="s">生成中</span>
                      </div>
                    </div>
                    <div class="lv3-prog">
                      <el-progress :width="54" type="dashboard" :percentage="85"></el-progress>
                    </div>
                  </div>
                </div>
              </div>
              <div class="steps-icon">
                <i class="el-icon-d-arrow-left"></i>
              </div>
            </template>
          </el-step>
          <el-step>
            <template slot="description">
              <div class="steps-item" :class="{'box-s':LineNumber === 4}">
                <div class="title">生成完成下载文档</div>
                <div class="cont">
                  <div class="lv-box-3">
                    <div class="lv3-left">
                      <div class="lv3-title">
                        <div class="l">
                          <img src="@/assets/images/icon/sxbg.png" alt="">
                          <span>标题</span>
                        </div>
                        <div class="r">
                          <span>{{ infoName==='订单查询'?'论文':infoName }}</span>
                          <span>{{ majorValue?'专业版':'标准版' }}</span>
                        </div>
                      </div>
                      <div class="lv4-right">
                        <span>下单时间: ****-**-**</span>
                        <span class="btn a" v-if="tableRowClassName(infoName)">无限改稿</span>
                        <span class="btn b">下载</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <img v-if="!majorValue" :src="require('@/assets/images/TimeLineImg/bg.jpg')" alt="知了写作">
      <img v-else :src="require('@/assets/images/TimeLineImg/bg2.jpg')" alt="知了写作">
    </div>
</template>
<script>
export default {
  props: ['LineNumber','infoName','majorValue'],
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    tableRowClassName(name){
      const specialName = ['毕业论文','开题报告','期刊论文']
      return specialName.includes(name)
    }
  }
}
</script>
<style scoped lang="less">
.time-line-img-box{
  position: relative;
  width: 100%;
  max-width: 440px;
  height: 100%;

  //background: url('../../assets/images/TimeLineImg/bg.jpg') no-repeat;
  //background-size: cover;
  //flex-shrink: 0;
  //padding: 0 40px;
  //box-sizing: border-box;
  //overflow: auto;

  background-color: #eefbff;
  overflow-y: auto;
  overflow-x: hidden;
  img{
    width: 100%;
    height: auto;
  }
  .img-title{
    position: absolute;
    top: 30px;
    left: 50%;
    width: 100px;
    line-height: 24px;
    margin-left: -50px;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    //border-bottom: 5px solid rgba(42, 171, 255, 0.7);
    background-image: linear-gradient(45deg, #48a4ff, #486dff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .steps-box{
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    padding: 0 30px;
    .steps-item{
      width: 320px;
      min-height: 120px;
      margin-top: -15px;
      margin-left: 10px;
      margin-bottom: 50px;
      border-radius: 10px;
      border: 2px solid rgba(255,255,255,0.5);
      .title{
        height: 36px;
        line-height: 36px;
        background-color: rgba(255,255,255,0.3);
        border-radius: 8px;
        border-bottom: 2px solid rgba(255,255,255,0.5);
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #3c3c3c;
      }
      .cont{
        padding: 10px;
        .lv-box{
          border-radius: 6px;
          background-color: rgba(255,255,255,0.5);
        }
        .lv-box-1{
          .lv1-title{
            position: relative;
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding-left: 20px;
            color: #3c3c3c;
          }
          .lv1-title::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 10px;
            width: 4px;
            height: 14px;
            margin-top: -7px;
            border-radius: 4px;
            background-color: #409EFF;
          }
          .lv1-cont{
            width: 100%;
            padding: 0 10px 10px 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .input{
              flex: 1;
              height: 34px;
              line-height: 34px;
              padding-left: 15px;
              border-radius: 6px;
              background-color: #ffffff;
              font-size: 14px;
              color: #999999;
              box-shadow: 0 0 5px rgba(0,0,0,0.07);
            }
            .btn{
              width: 80px;
              min-width: 80px;
              height: 30px;
              line-height: 30px;
              margin-left: 10px;
              text-align: center;
              border-radius: 6px;
              background-color: #409EFF;
              font-size: 14px;
              color: #ffffff;
            }
          }
        }
        .lv-box-2{
          .lv2-title{
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding-left: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            img{
              width: 16px;
              height: 16px;
            }
            span{
              padding-left: 8px;
              font-size: 14px;
              color: #3c3c3c;
            }
          }
          .lv2-cont{
            width: 100%;
            padding: 0 10px 10px 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .left-ewm{
              position: relative;
              width: 60px;
              height: 60px;
              padding: 6px;
              border-radius: 6px;
              background-color: #ffffff;
              .icon{
                position: absolute;
                z-index: 1;
                top: 12px;
                left: 13px;
              }
              .ewm-icon{
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                font-size: 60px;
                color: #82d2ff;
              }
            }
            .right-text{
              padding-left: 10px;
              .t{
                font-size: 12px;
                color: #3c3c3c;
              }
              .m{
                font-size: 12px;
                color: #3c3c3c;
                span{
                  font-size: 14px;
                  font-weight: bold;
                  color: #e31c1c;
                }
              }
              .f{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                img{
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
                }
                span{
                  font-size: 12px;
                  color: #3c3c3c;
                }
              }
            }
          }
        }
        .lv-box-3{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-start;
          .lv3-left{
            flex: 1;
            padding: 0 10px;
            border-radius: 6px;
            background-color: rgba(255,255,255,0.5);
            .lv3-title{
              height: 34px;
              line-height: 34px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              .l{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                img{
                  width: 16px;
                  height: 16px;
                }
                span{
                  padding-left: 8px;
                  font-size: 14px;
                  color: #3c3c3c;
                }
              }
              .r{
                span{
                  padding: 2px 6px;
                  margin-left: 6px;
                  border-radius: 4px;
                  font-size: 12px;
                  color: #3c3c3c;
                  background-color: #c6edfb;
                }
              }
            }
            .lv3-right{
              padding: 10px 0;
              border-top: 1px solid #e0f2fc;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              span{
                font-size: 12px;
                color: #3c3c3c;
              }
              .s{
                padding: 2px 6px;
                margin-left: 6px;
                border-radius: 50px;
                border: 1px solid #ffffff;
                background-color: rgba(255,255,255,0.3);
              }
              .btn{
                padding: 2px 8px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
              }
              .a{
                background-color: #6cb4ff;
              }
              .b{
                background-color: #00b034;
              }
            }
            .lv4-right{
              padding: 10px 0;
              border-top: 1px solid #e0f2fc;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              span{
                margin-right: auto;
                font-size: 12px;
                color: #3c3c3c;
              }
              .btn{
                padding: 2px 8px;
                margin-right: 0;
                margin-left: 6px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
              }
              .a{
                background-color: #6cb4ff;
              }
              .b{
                background-color: #00b034;
              }
            }
          }
          .lv3-prog{
            width: 70px;
            min-width: 70px;
            height: 70px;
            margin-left: 10px;
            border-radius: 6px;
            background-color: rgba(255,255,255,0.5);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .box-s{
      border: 2px solid rgba(255,255,255,1);
      box-shadow: 2px 5px 12px rgba(0, 128, 255, 0.25);
      .title{
        border-bottom: 2px solid rgba(255,255,255,1);
        background: linear-gradient(90deg, #82d2ff, #c7edfc);
      }
    }
    .steps-icon{
      margin-left: 30px;
      text-align: center;
      transform: translateY(-15px);
      i{
        font-size: 20px;
        transform: rotate(-90deg);
      }
    }
    /deep/ .el-step{
      .el-step__line {
        background-color: #e8fbfa;
      }
      .is-text{
        border: 0;
        background-color: #e8fbfa;
      }
      .is-finish {
        color: #409EFF;
        .is-text{
          color: #ffffff;
          background-color: #409EFF;
        }
      }
      .is-process {
        color: #9ad4ff;
      }
      .is-wait {
        color: #9ad4ff;
      }
    }

  }
}
//.major-col{
//  background: linear-gradient(90deg, #fff6d7, #fdecbe, #fff6d7);
//  .img-title{
//    //background: linear-gradient(90deg, #f5a81c, #f97640);
//    background-image: linear-gradient(45deg, #f5a81c, #f97640);
//  }
//  .steps-box{
//    .box-s{
//      box-shadow: 2px 5px 12px rgba(255, 175, 36, 0.25);
//      .title{
//        background: linear-gradient(90deg, #fce1b2, rgba(252, 225, 178, .56));
//      }
//    }
//    .steps-item{
//      .cont{
//        .lv-box-1{
//          .lv1-title::after{
//            background: linear-gradient(180deg, #ffc256, #fe9b59);
//          }
//          .lv1-cont{
//            .btn{
//              background: linear-gradient(90deg, #ffc256, #fe9b59);
//            }
//          }
//        }
//        .lv-box-2{
//          .lv2-cont{
//            .left-ewm{
//              .ewm-icon{
//                color: #ffc256;
//              }
//            }
//          }
//        }
//        .lv-box-3{
//          .lv3-left{
//            .lv3-title{
//              .r{
//                span{
//                  background-color: #fce1b2;
//                }
//              }
//            }
//          }
//        }
//      }
//    }
//    /deep/ .el-step{
//      .el-step__line {
//        background-color: #fffbf7;
//        border-color: #fe9b59;
//      }
//      .is-text{
//        border: 0;
//        background-color: #fffbf7;
//      }
//      .is-finish {
//        color: #fe9b59;
//        .is-text{
//          color: #ffffff;
//          background-color: #fe9b59;
//        }
//      }
//      .is-process {
//        color: #ffc256;
//      }
//      .is-wait {
//        color: #ffc256;
//      }
//    }
//  }
//}
.major-col{
  background: linear-gradient(90deg, #d7c0f7, #c3a3fa, #d7c0f7);
  .img-title{
    background-image: linear-gradient(90deg, #6b36fd, #bd6fff);
  }
  .steps-box{
    .box-s{
      box-shadow: 2px 5px 12px rgba(116, 36, 255, 0.25);
      .title{
        background: linear-gradient(90deg, #d099ff, rgba(197, 178, 252, 0.56));
      }
    }
    .steps-item{
      .cont{
        .lv-box-1{
          .lv1-title::after{
            background: linear-gradient(180deg, #6b36fd, #bd6fff);
          }
          .lv1-cont{
            .btn{
              background: linear-gradient(90deg, #6b36fd, #bd6fff);
            }
          }
        }
        .lv-box-2{
          .lv2-cont{
            .left-ewm{
              .ewm-icon{
                color: #d099ff;
              }
            }
          }
        }
        .lv-box-3{
          .lv3-left{
            .lv3-title{
              .r{
                span{
                  background-color: #d7c0f7;
                }
              }
            }
          }
        }
      }
    }
    /deep/ .el-step{
      .el-step__line {
        background-color: #fffbf7;
        border-color: #6b36fd;
      }
      .is-text{
        border: 0;
        background-color: #fffbf7;
      }
      .is-finish {
        color: #6b36fd;
        .is-text{
          color: #ffffff;
          background-color: #6b36fd;
        }
      }
      .is-process {
        color: #bd6fff;
      }
      .is-wait {
        color: #bd6fff;
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
@media screen and (min-width: 1301px) and (max-width: 1440px) {
  .time-line-img-box{
    .steps-box{
      transform: scale(0.9);
      margin: -50px 0 0 -25px;
    }
  }
}
@media screen and (min-width: 901px) and (max-width: 1300px) {
  .time-line-img-box{
    .steps-box{
      transform: scale(0.8);
      margin: -80px 0 0 -40px;
    }
  }
}
@media screen and (max-width: 900px) {
  .time-line-img-box{
    display: none;
  }
}
</style>
