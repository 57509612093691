<template>
  <!-- 底部区域 -->
  <div v-if="false"></div>
  <div class="footInfo" v-else>
<!--    <p>-->
<!--      <el-tooltip class="item" effect="light" :content="footInfo" placement="top">-->
<!--        <span>{{footInfo}}</span>-->
<!--      </el-tooltip>-->
<!--    </p>-->
<!--    <p>-->
<!--      <el-tooltip class="item" effect="light" :content="footInfo1" placement="top">-->
<!--        <span>{{footInfo1}}</span>-->
<!--      </el-tooltip>-->
<!--    </p>-->
    <p class="footInfoBtn" @click="beianBtn">
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAACXlBMVEUAAADax4rGxqP556zmsVD/6KXu5JvwvFPglEFnY3nnpEjosU1+XFTmuVfu13Hrv1jpsFa1k3mne2HlvnPdqmPqz3fu3YrJoWv04J/mz5z246aSi3i2p2/0zGj82WDrtFjar1O7klO4dkfimUJcWnbho0rgr1vntFfmvFflvGOZdFKXZkOUemnmv2XkuWWVe2ziqlXnt1Tr0HDpwl6ObmCkbEKqhVyOc2jdoVHmuljfrmDq0nPapWDUnErqy3XjtWXesXLrzHjz34rqzYfmw3fy4YrqyYDevW/dzoTIhkndsXLRsIneuHjlyIzQroTjuXjlyYfktWvw2prhvoHTmGfx45j3773TuYT/25LdijyKf321oXU+Nm3cumXfuWLtwlzpwVz40FvpvlvqvFfouFXjrlHipEjcmEfknEXPbzfbZyzZQR/YMBrWKBrhJhLdHxDTGg5HV4xBT4YYKYaWk4SDgYIWIYBybH0qLn1KS3tlZHpNS3WGfHIcHXEwLHAnF28rKW07NGuzn2pNQ2ruzWnDrGnuy2jryGjAnGgoH2j1x2RCNGPcrmIsEGGQdl/pu17YtV7FoV3muVxoRVzWqFvSmVp2SVbyw1XbkVTuvFPfo1PgmVPpt1LcnFLWkVLlp1F7W1HpsU92QE5oMUtFAUrpqUjnjUFOAEHUgz+DUj14FzvbczieMTdhATfodjXWcDXcbjXVcTS/Xi/hay7WXS3cWS3vYyvaVSrUVymnJSjXSyeKDSbNSiWfHCPMTyLFQiHkSR/lPxvVLRrPLBnUJRfUHhbaJxXVGxTZJxPNFhBdOhm/AAAAWXRSTlMABQIU/hIJ/v79/Pj29PPx4cC6s7CNfmxZWRv+/v7+/v7+/v78/Pr6+fPz8vHq6ujl5eTj4ODe2NTQzMW6ubKsnJeQkJCJiIOBgX9ubGpoW1lMREM0JR8dDgvYx1gAAAE7SURBVBjTYgADJmZJQUFJZiYGOGD2EzLV1jIT8paCibCJ86zctGPD1D4ecUaICKuPyYLMYznZOfNqzP0jwEJBOt1KB4/mHjqZ3dGsHwxW5M4Zk5l/PPdIQcGMShUPVqBlgQLlMSvyDq+P3HVidjWnkQQTA5MXV1SFYtb+jZGrl02si2J3Y2JgFMmILl68efueLVvXLSqLXirCCBgDow1HT+GS/AP7srblLS+K5bIHCjmodpXUr929d+eaVb3SsuouQCHhufPjShsbJk/rrK2KW8hiBxRyVkvnkI9tnaDQJDNFOU1DDOjRMEfe9Mjpiewz5RIzZmmKMoPcKqGbkJqSkBSfnDqpXS+ACeRpJ76W/uSUpDlpLPFtfK5soLAKFbU05Odm4eblN7YWk4KEGWOIp62FgYCVsG84SAAAL7BaooX965sAAAAASUVORK5CYII=" />
      <el-tooltip class="item" effect="light" :content="footInfo2" placement="top">
        <span>{{footInfo2}}</span>
      </el-tooltip>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      footInfo: '版权所有：湖北小杏科技有限公司',
      footInfo1: '公司地址：湖北省武汉市东湖新技术开发区高新六路15号平安光谷春天5-2-2102',
      footInfo2: '工信部备案号: 鄂ICP备2024088293号-1',
      beianUrl: 'https://beian.miit.gov.cn/',
    }
  },
  methods: {
    beianBtn(){
      window.open(this.beianUrl, '_blank');
    }
  }
}
</script>
<style scoped lang="less">
.footInfo {
  width: 100%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 0;
  color: #7da0d4;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    max-width: 33%;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    span{
      width: 100%;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .footInfoBtn:hover {
    cursor: pointer;
    color: #409EFF;
    text-decoration: underline;
  }
}
</style>
