<template>
  <div class="form-box" :class="majorValue?'major-col':''">
    <FormTitle :infoName="infoName" />
    <div class="form-list">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入任务书标题" class="form-item-pc-show">
          <el-input v-model="ruleForm.title" style="position: relative; z-index: 11;"
                    type="text"
                    placeholder="请输入完整的标题，标题越完整，创作内容越准确"
                    @focus="openTitleInfo"
                    maxlength="50" >
            <div class="btn-i-icon"
                 v-if="ruleForm.title.length > 0"
                 slot="suffix"
                 @click="handleIconClick">
              <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9406"
                   width="20" height="20"><path fill="#2f6bf1" d="M693.952 630.592l193.088 193.152-60.352 60.288L633.6 690.944l60.352-60.352zM614.4 72.512c12.8-4.224 29.888 4.288 29.888 17.088l34.112 226.112c4.288 4.288 4.288 8.576 4.288 12.8L819.2 486.4c8.512 8.512 8.512 25.6-8.512 29.888l-187.776 102.4c-4.224 4.224-8.512 4.224-8.512 8.512L490.688 832c-8.576 12.8-25.6 17.088-34.176 4.288l-153.6-174.976c-4.224-4.224-8.512-8.512-12.8-8.512L89.6 627.2c-12.8-4.288-21.312-17.088-17.088-29.888l89.6-196.224v-8.576l-25.6-226.112c-4.224-17.088 8.576-25.6 21.376-25.6l230.4 8.512H396.8z m-42.688 110.976l-145.024 51.2c-12.8 4.224-29.888 4.224-42.688 4.224L226.112 230.4 243.2 379.712c0 17.088 0 34.176-8.512 51.2l-51.2 123.776 119.424 12.8c25.6 0 51.2 12.8 68.288 34.112l93.888 110.912 81.024-128c8.576-17.024 21.376-29.824 38.4-38.4l119.488-64L618.688 384c-12.8-17.088-21.376-34.112-21.376-55.488l-25.6-145.024z" p-id="9407"></path>
              </svg>
              <span>优化标题</span>
            </div>
          </el-input>
          <TitleInfoBox ref="TitleInfo"
                        :title="ruleForm.title"
                        :code="ruleForm.creationDocCode"
                        @acceptTitle="acceptTitle"/>
        </el-form-item>
        <el-form-item label="请输入任务书标题" class="form-item-h5-show">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入完整的标题，标题越完整，创作内容越准确"
                    maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="请选择科目(没有所选专业请输入)" v-if="taskBookInfo.subjectList">

          <div class="show-panel" @click="showPanel('startPointId')">
            <el-input
              v-model="ruleForm.extParam.subject"
              placeholder="请输入或选择科目"
            ></el-input>
            <el-cascader id="startPointId" placeholder="请选择" v-model="ruleForm.extParam.subject"
                         filterable
                         :options="taskBookInfo.subjectList"
                         :props="{label: 'name',value: 'name',children: 'children',expandTrigger:'hover'}"
                         @blur="handleBlur"
                         @change="handleChange"></el-cascader>
          </div>

          <div class="show-drawer">
            <el-input
              v-model="ruleForm.extParam.subject"
              placeholder="请输入科目"
              clearable
            ></el-input>
            <el-button type="text" @click="dialogDrawer">
              选择科目
              <i class="el-icon-arrow-right"></i>
            </el-button>

            <DrawerH5 ref="Drawer" @subject="refChild" />
          </div>

        </el-form-item>
        <el-form-item label="请选择学历">
          <!-- v-model="ruleForm.extParam.education" -->
          <div class="form-xl radio-pc-show">
            <div class="form-li" v-for="(item,index) in taskBookInfo.education" :class="item.active === 1 ? 'active':''"
              @click="clickNav(index)">
              <div class="form-check" v-if="item.active === 1"><i class="el-icon-check"></i></div>
              <h3>{{item.label}}</h3>
              <p>{{item.tip}}</p>
            </div>
          </div>
          <el-radio-group v-model="ruleForm.extParam.education" class="radio-h5-show h5-el-radio" @input="clickNavH5">
            <el-radio v-for="item in taskBookInfo.education" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="请选择模型">
          <el-radio-group v-model="ruleForm.extParam.modelCode" @input="inputRadioGroup">
              <el-radio :label="1">标准版</el-radio>
              <el-radio :label="2">
                专业版
                <el-tooltip class="item" effect="light" content="篇幅更长,研究领域更广,内容更精准" placement="top-start">
                  <i class="el-icon-info" style="color: #ffaa00;"></i>
                </el-tooltip>
              </el-radio>
            </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
  import TaskBookApi from '@/api/taskBook'
  import CommonApi from "@/api/common"
  import { orderMixin } from '@/utils/orderMixin'
  import { validateTitle } from '@/utils/validate'
  import DrawerH5 from '@/components/drawerH5/drawerH5.vue'
  import FormTitle from "@/components/FormTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
  import TitleInfoBox from "@/components/titleInfoBox/index.vue"
  export default {
    components: {TitleInfoBox, NoticeWrapper, FormTitle, DrawerH5 },
    mixins: [orderMixin],
    props: ['infoName'],
		data() {
			return {
        ruleForm: {
          title: '',
          creationDocId: 9,
          creationDocCode: 'TASK_BOOK',
          channelCode: null,
          extParam: {
            version: 1,
            modelCode: 1,
            education: null,
            subject: ''
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入任务书标题', trigger: 'blur' },
        //     { validator: validateTitle, trigger: 'blur' }
        //   ],
        //   extParam: {
        //     subject: [
        //       { required: true, message: '请选择科目', trigger: 'change' }
        //     ],
        //     education: [
        //       { required: true, message: '请选择学历', trigger: 'change' }
        //     ],
        //   }
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        taskBookInfo: {
          subjectList: '',
          education: ''
        },
        paramsData: '', // 储存跳页面的传值
        majorValue: false, // 选择模型 界面主题颜色
			}
		},
		created() {
      this.getEducation()
      this.getSubject()
    },
    beforeRouteLeave(to, from, next){
      this.inputRadioGroup()
      next();
    },
		methods: {
      inputRadioGroup(val){
        let type = val && val === 2
        this.majorValue = type
        this.$emit('getMajorValue',type)
      },
      // 选择学历 移动端
      clickNavH5(val){
        // console.log(val)
        this.getWordsNum(val)
      },
      handleChange(value) {
        this.ruleForm.extParam.subject = value[1]
      },
      clickNav(index) {
        this.taskBookInfo.education.forEach(item => {
          item.active = 0
        })
        this.taskBookInfo.education[index].active = 1
        this.ruleForm.extParam.education = this.taskBookInfo.education[index].value
      },
      // 获取科目
      getSubject() {
        CommonApi.getSubject().then(res => {
          // console.log(res)
          this.taskBookInfo.subjectList = res.data;
          // 默认选中的科目
          // this.ruleForm.extParam.subject = '计算机科学与技术类'
        }).catch(() => {
        })
      },
      //获取学历接口
      getEducation(){
        TaskBookApi.getEducation().then(res => {
          let dataList = res.data
          dataList.forEach((item,index)=>{
            item.active = 0
          })
          if(dataList.length >= 3){
            dataList[0].tip = '清晰架构，内容丰富的基础设计，确保每处细节与数据都精准无误。'
            dataList[1].tip = '强化逻辑思维，深挖理论精髓，广纳多元主题，达成高水平分析力。'
            dataList[2].tip = '潜心研究，力求创新，深挖精髓，打造原创性高、研究价值深厚的文章。'
          }
          this.taskBookInfo.education = dataList;
          this.clickNav(1)
        }).catch(() => {
        })
      },
      submitForm(formName) {
        let _this = this
        if(_this.ruleForm.title === ''){
          return _this.$message({
            message: '请输入任务书标题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }else if(_this.ruleForm.title.length < 5){
          return _this.$message({
            message: '标题长度需要至少5个字!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        if(_this.ruleForm.extParam.subject === ''){
          return _this.$message({
            message: '请输入或选择科目!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        _this.paramsData = JSON.parse(JSON.stringify(_this.ruleForm))
        let ruleFormInfo = JSON.parse(JSON.stringify(_this.ruleForm))
        ruleFormInfo.extParam = JSON.stringify(ruleFormInfo.extParam)
        //生成订单 mixin
        _this.createOrder(ruleFormInfo,_this.paramsData)

        // _this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     _this.paramsData = JSON.parse(JSON.stringify(_this.ruleForm))
        //     let ruleFormInfo = JSON.parse(JSON.stringify(_this.ruleForm))
        //     ruleFormInfo.extParam = JSON.stringify(ruleFormInfo.extParam)
        //     //生成订单 mixin
        //     _this.createOrder(ruleFormInfo,_this.paramsData)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // },
      // 打开隐藏的 科目选择器
      showPanel(val){
        document.getElementById(val).click()
      },
      handleBlur(e){
        // console.log(e.target.value)
        this.ruleForm.extParam.subject = e.target.value
      },
      // 移动端 选择科目 组件相关
      dialogDrawer(){
        let params = {
          show: true,
          info: this.taskBookInfo
        }
        this.$refs.Drawer.showDrawer(params)
      },
      refChild(name){
        this.ruleForm.extParam.subject = name
      },
      openTitleInfo(){
        this.$refs.TitleInfo.openItme()
      },
      acceptTitle(item){
        this.ruleForm.title = item
      },
      handleIconClick(ev) {
        // console.log(ev);
        this.$refs.TitleInfo.optimize()
      }
		}
	}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
.form-item-pc-show{
  display: block;
}
.form-item-h5-show{
  display: none;
}
.btn-i-icon{
  height: 30px;
  padding: 0 8px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #e7f3ff;
  color: #2f6bf1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  span{
    margin-left: 5px;
    font-weight: bold;
  }
}
.btn-i-icon:hover{
  background-color: #d8e3f8;
}
@media screen and (max-width: 900px) {
  .form-item-pc-show{
    display: none;
  }
  .form-item-h5-show{
    display: block;
  }
}
</style>
