import { render, staticRenderFns } from "./course_paper.vue?vue&type=template&id=34763bc7&scoped=true&"
import script from "./course_paper.vue?vue&type=script&lang=js&"
export * from "./course_paper.vue?vue&type=script&lang=js&"
import style0 from "./course_paper.vue?vue&type=style&index=0&id=34763bc7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34763bc7",
  null
  
)

export default component.exports