// 导航菜单的枚举
const UserList = [{
  headImgUrl: 'photo1.jpg',
  name: '程*磊',
  time: '2024-07-29',
  txt: '知了写作AI对于初稿的快速生成非常有帮助。当我面对紧迫的论文截止日期时，它成为了我的得力助手。AI能够快速整理思路并生成一个结构完整的初稿，这不仅让我有了明确的写作方向，还大大缩短了我从零开始构思和撰写的时间。当然，在后续阶段，我还是会根据自己的研究和理解对初稿进行深入的修改和完善，以确保论文的质量和学术价值。'
},{
  headImgUrl: 'photo7.jpg',
  name: '林*红',
  time: '2024-08-11',
  txt: '知了写作AI的智能化程度令人印象深刻。它不仅能够根据输入的关键词和主题生成文章，还能根据上下文语境进行智能推理和补充。在撰写一篇涉及多个学科交叉的论文时，AI能够自动整合相关信息，并提供有见地的观点，这对我来说是一个巨大的帮助。不过，我也意识到，在使用AI生成的内容时，仍然需要谨慎筛选和验证，以确保信息的准确性和可靠性。'
},{
  headImgUrl: 'photo3.jpg',
  name: '陈*南',
  time: '2024-08-19',
  txt: '知了写作AI的用户界面友好且易于操作。即使是对于不太熟悉技术的人来说，也能轻松上手并快速生成论文。AI的提示和建议功能也非常实用，它们能够引导我逐步完成论文的撰写过程，并在需要时提供额外的帮助和支持。此外，AI还支持多种文件格式和导出选项，这使得我在完成论文后能够轻松地进行后续的排版和提交工作。'
},{
  headImgUrl: 'photo8.jpg',
  name: '许*明',
  time: '2024-08-21',
  txt: '知了写作AI在论文写作方面表现非常出色。它能够迅速生成文章大纲，并自动填充内容，大大节省了我在构思和撰写上的时间。其生成的论文语言流畅、逻辑清晰，让我能够专注于对论文的深度打磨，而不是在基础的写作上花费过多精力。此外，AI还提供了内容优化建议，帮助我进一步完善了论文的结构和论述。'
},{
  headImgUrl: 'photo9.jpg',
  name: '吴*东',
  time: '2024-08-24',
  txt: '使用知了写作AI写论文的体验非常愉快。AI能够模仿多种写作风格，让我在撰写不同类型的论文时有了更多的选择。例如，在撰写一篇关于新技术的论文时，我选择了科技类写作风格，结果生成的论文语言简洁明了，非常适合科技类文章的表述。同时，AI还能根据我的需求调整文章的语气和风格，使得论文更具个性化。'
},{
  headImgUrl: 'photo2.jpg',
  name: '肖*元',
  time: '2024-08-29',
  txt: '知了写作AI的素材库非常丰富，为我在写作过程中提供了大量的参考和灵感。在撰写一篇关于教育改革的论文时，AI不仅提供了大量的相关数据和案例，还根据我的输入内容推荐了相关的教育理论和观点，使我的论文内容更加充实和深入。这极大地提升了我的写作效率和论文质量。'
},{
  headImgUrl: 'photo4.jpg',
  name: '付*江',
  time: '2024-09-09',
  txt: '尽管知了写作AI在论文写作方面提供了很多便利，但我认为它仍然无法完全替代人类写作。例如，在撰写一些需要深入思考和创新的论文时，AI的生成内容可能缺乏足够的深度和独特性。因此，在使用AI的同时，我还需要结合自己的专业知识和思考进行创作，以确保论文的质量和独特性。'
},{
  headImgUrl: 'photo6.jpg',
  name: '李*万',
  time: '2024-09-13',
  txt: '知了写作AI在论文校对和修改方面也非常实用。它能够及时发现并纠正文章中的语法错误和逻辑问题，提高了论文的专业性和可读性。此外，AI还能提供实时的反馈和修改建议，帮助我在写作过程中不断调整和优化文章的结构和表述。这使得我在完成论文后更加自信地提交给导师或期刊进行评审。'
}]
export default UserList
