<template>
  <div style="height: 100%;">
    <div class="index-box" v-if="isMobile === false">
      <el-container>
        <el-header>
          <CaseHeads />  <!-- @navName="getNavName" -->
        </el-header>
        <el-container style="margin-top: -60px">
          <el-aside class="index-aside" style="padding-top: 60px;">
            <CaseMenu @navName="getNavName" @navNameList="getNavNameList" @mentList="transferMentList"></CaseMenu>
          </el-aside>
          <el-container style="padding-top: 60px;max-width: 440px;min-width: 320px;" v-if="TimeLineShow">
            <TimeLineImg :LineNumber="LineNumber" :infoName="navName" :majorValue="majorValue"/>
          </el-container>
          <el-container class="cont-ainer" style="padding-top: 60px;">
            <el-main class="main-index-box">
              <router-view :infoName="navName"
                           :transferMent="transferMent"
                           :contList="contList"
                           @getMajorValue="getMajorValue"
                           @thesisTimeLine="thesisTimeLine"
                           @TimeLineNumber="msgTimeLineNumber"></router-view>
            </el-main>
            <el-footer style="height: auto;">
              <MenuFoot></MenuFoot>
            </el-footer>
          </el-container>
        </el-container>
        <!-- 右悬浮 -->
        <suspension></suspension>
      </el-container>
    </div>
    <div class="index-box2" v-else>
      <el-container class="main-box2">
        <el-aside class="box2-aside" width="80px"> <!-- v-if="showLeftMenu" -->
          <MenuH5 @navName="getNavName" @mentList="transferMentList" />
        </el-aside>
        <el-container>
          <el-main style="padding: 10px;">
            <UserLogin></UserLogin>
            <router-view :infoName="navName" :transferMent="transferMent"></router-view>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </div>
</template>
<script>
import CaseHeads from '../../components/caseHeads/caseHeads.vue'
import CaseMenu from '../../components/caseMenu/caseMenu.vue'
import UserLogin from "@/components/userLogin/index.vue"
import MenuH5 from "@/components/menuH5/menuH5.vue"
import MenuFoot from "@/components/menuFoot/index.vue"
import suspension from "@/components/suspension/suspension.vue"
import LeftMenu from "@/views/user_promot/components/LeftMenu/index.vue"
import RequestlogApi from "@/api/requestlog"
import TimeLineImg from "@/components/TimelineImg"

export default {
  components:{
    LeftMenu,
    suspension,
    MenuFoot,
    MenuH5,
    UserLogin,
    CaseHeads,
    CaseMenu,
    TimeLineImg
  },
  data() {
    return {
      // activeValue: 10,
      transferMent: [],
      windowWidth: document.body.clientWidth,
      isMobile: false,
      navName: '', //保存路由title
      contList: '', //保存路由list
      // showLeftMenu: true,
      showLeftList: true,
      LineNumber: 1,
      TimeLineShow: false,
      majorValue: false
    }
  },
  created() {
    this.isMobile = this.windowWidth < 901;
    // let toPath = this.$route.path
    // this.showLeftMenu = toPath !== '/index/promot'
    this.report()
  },
  watch: {
    // 监听页面宽度
    windowWidth(newWidth) {
      this.isMobile = newWidth < 901;
    },
    $route(to,from){
      // 这里会在路由路径变化时执行
      localStorage.removeItem('orderId')
      localStorage.removeItem('creationDocCode')
      this.toPath(to.path)
      // this.showLine(to.path)
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.documentElement.clientWidth
      })()
    }
  },
  methods: {
    transferMentList(val){
      this.transferMent = val
      let toPath = this.$route.path
      this.toPath(toPath)
    },
    getNavName(name){
      this.navName = name
    },
    getNavNameList(item){
      this.contList = item
    },
    getMajorValue(type){
      this.majorValue = type
    },
    toPath(path){
      this.showLeftList = false
      this.transferMent.some(item => {
        if(path === item.url){
          this.showLeftList = true
          this.LineNumber = 1
          this.TimeLineShow = true
        }else if(path === '/index/IntelligentPay'){
          this.LineNumber = 2
          this.TimeLineShow = true
        }else if(path === '/index/paySuccess'){
          this.LineNumber = 3
          this.TimeLineShow = true
        }else if(path === '/index/order'){
          this.LineNumber = 3
          this.navName = '订单查询'
          this.TimeLineShow = true
        }else if(path === '/index/caseLibrary' || path === '/index/pptLibrary' || path === '/index/promot'){
          this.LineNumber = 1
          this.TimeLineShow = false
        }
      })
    },
    // showLine(path){
    //   this.transferMent.some(item => {
    //     if(path === item.url){
    //       this.TimeLineShow = true
    //       this.LineNumber = 1
    //     }else if(path === '/index/IntelligentPay'){
    //       this.TimeLineShow = true
    //       this.LineNumber = 2
    //     }else if(path === '/index/paySuccess' || path === '/index/order'){
    //       this.TimeLineShow = true
    //       this.LineNumber = 3
    //     }else if(path === '/index/caseLibrary' || path === '/index/pptLibrary' || path === '/index/promot'){
    //       this.TimeLineShow = false
    //       this.LineNumber = 1
    //     }
    //   })
    // },
    // 上报日志
    report(){
      let data = {
        type: 1,
        channelCode: '',
        description: ''
      }
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      RequestlogApi.report(data,key,time,infoMd5).then(res => {
        // console.log(res.data)
      }).catch(() => {
      })
    },
    msgTimeLineNumber(num){
      setTimeout(()=>{
        this.TimeLineShow = true
        this.LineNumber = num
      },200)
    },
    thesisTimeLine(type){
      this.TimeLineShow = type
      this.LineNumber = 1
    }
  }
}
</script>
<style scoped lang="less">
.index-box {
  display: block;
  height: 100%;
  width: 100%;

  .el-container {
    position: relative;
    height: 100%;
    //background: url("../../assets/images/main-bg2.png") no-repeat center -104px;
    //background-size: cover;
    .index-aside{
      width: auto !important;
      overflow: initial;
    }
  }
  .cont-ainer::before{
    position: absolute;
    z-index: -1;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(160deg, #7768ff, #ffffff, #ffffff);
    opacity: 0.1;
  }
  .main-index-box{
    padding: 0;
  }
  .ad-show{
    width: calc(100% - 350px) !important;
    margin-left: 350px !important;
  }
}
.index-box2{
  display: block;
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/bg.jpg) no-repeat right 0;
  background-size: cover;
  .main-box2{
    width: 100%;
    height: 100%;
    .box2-aside{
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #ffffff;
      box-shadow: 3px 0 5px rgba(51, 185, 255, 0.1);
    }
  }
}
</style>
