const agreement = {
  agree: '<div class="xunyun-box">\n' +
    '  \n' +
    '  \n' +
    '    <div class="xunyun-title">知了写作用户协议</div>\n' +
    '    <p>欢迎来到知了写作</p>\n' +
    '    <p>\n' +
    '      知了写作是由本平台提供的互联网软件服务。本服务条款（下称“服务条款”）是您与本平台之间关于您（“您”或“用户”）访问和使用知了写作以及该平台内的其他服务（下称“服务”）的主要协议。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      我们特别提醒您在使用本服务之前认真阅读（未满18周岁的未成年人应在法定监护人陪同下阅读）、充分理解本协议的全部条款，尤其是服务费用、免责声明、法律管辖和适用等加粗显示的条款。如您不同意本协议的任何条款，将导致我们无法为您提供完整的产品和服务，您可以选择停止使用本服务。如您自主选择同意本协议，或您的注册、登录、使用等行为将视为您对本协议的接受，并视为您接受并遵守本协议各项条款。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      如对本协议内容有任何疑问、意见或建议，您可通过发送邮件至【\n' +
    '      xiaoxing2024@126.com】与我们联系。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      特别提示：本服务提供的所有内容均由人工智能模型生成，可能出现错误或遗漏，本服务对其生成内容的准确性、完整性和功能性不作任何保证，并且其输出的内容均不代表本服务提供者的态度或观点。特别提示，您在使用本服务生成医疗、法律等相关专业内容时，本服务仅提供内容建议，不代表专业内容。若您需要医疗诊断、法律服务等专业内容，必须咨询医生和相关法律专业人士，并在专业人士的指导下作出决策。总之，本服务提供的内容，应仅供您参考，不构成任何建议或承诺，我们不承担您因使用本服务给您或任何第三方产生的任何损害或责任。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">1. 服务介绍</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1.1 知了写作 依托\n' +
    '      ChatGPT及自研算法和资料库，提供文字内容生成服务。用户需输入标题、选择相关设置等，知了写作会基于用户输入输出相应的内容作为响应，以文字内容为主。知了写作\n' +
    '      会收集和分析用户的输入和输出信息，以提高识别和响应能力，改进本服务的智能性和内容质量。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      1.2\n' +
    '      本服务具体功能将随着用户反馈、技术升级，或相关监管政策的变化不断迭代更新。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      1.3\n' +
    '      您在使用本服务中由第三方（比如通过插件、链接等）提供的产品或服务时，请遵守第三方公布的用户协议、使用规则、隐私政策等法律文件，在法律法规允许的范围内，我们无法对第三方产品及任何内容负责。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">2. 账号登录</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1）为访问本服务，您应当按照页面引导输入您的\n' +
    '      知了写作账号、密码、手机号，验证通过并签署本协议后，即完成网页端注册和登录。账号注册过程中，您应当仔细阅读《\n' +
    '      知了写作户协议》、《\n' +
    '      知了写作隐私政策》的条款并按照页面上的提示完成全部的注册程序。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      2）本服务主要面向成人。如果您未满18周岁，请在法定监护人陪同下阅读本协议，并在征得法定监护人的同意后使用本服务。知了写作充分理解保护未成年人的重要性，并会根据法律法规的要求及行业主流实践采取相应的保护措施。与此同时，请监护人合理引导和约束未成年人用户对本服务的使用，共同营造良好网络环境，帮助未成年人养成良好上网习惯，避免过度依赖或者沉迷本服务。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      3）为落实国家法律法规的有关实名认证的要求，使您更好地使用\n' +
    '      知了写作产品及相关服务，保障您的账号安全，您在注册时需要向我们提供真实的手机号码。此外，为了向您提供已有或未来上线的部分功能，我们可能还会严格依照法律法规要求向您收集其他个人信息。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      4）您理解并承诺，您所设置的账号不得违反国家法律法规相关规定，您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人混淆的方式）开设账号，不得恶意注册账号（包括但不限于频繁注册、批量注册账号等行为）。您在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益、有害社会道德风尚的行为。知了写作有权对您提交的信息进行审核。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      5）为符合相关法律法规的要求，保障您的账号安全，\n' +
    '      知了写作可能会定期或不定期采用不同的方式对您的身份进行验证，包括但不限于要求输入验证码、手机号验证、邮箱认证等。如您无法完成验证或拒绝验证的，\n' +
    '      知了写作可能合理怀疑您的账号存在安全风险，并暂停向您提供本服务的部分或全部服务，并有权采取进一步措施。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      6）通常而言本服务对用户认定标准为：同一\n' +
    '      知了写作账号、手机号、微信号等均视为一个用户。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      7）您应该自行保管好您的账号信息（包括账号、密码、验证码等）。因您对账号信息保管不当、主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。如发现任何未经授权使用您账号登录使用本服务或其他可能导致您账号遭窃、遗失的情况，建议您立即通知知了写作。您理解知了写作对您的请求采取行动需要合理时间，除知了写作存在过错外，知了写作对在采取行动前已经产生的后果不承担责任。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      8）您可以随时注销您的账号并终止与\n' +
    '      知了写作的服务关系，但请您理解，即使您的账号已注销，您仍需对您注销前的账号使用行为承担相应法律责任。特别地，在您注销账号前，您应确保账号不存在未完结交易或其他未了结的权利义务，且不存在其他因注销可能引发纠纷或者导致已发生的纠纷难以解决的情况，包括但不限于您的账号被冻结、限制，您的注销是出于躲避调查取证的目的等。请注意，若我们发现您在使用本服务过程中存在违法行为，我们将依法保存该等记录。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      9）如您的账号有余额，请提出或转出账号内全部余额。特别提醒您，一旦您注销本服务账号，如届时该账号下还存在虚拟收益的（包括但不限于积分、虚拟货币、已经购买但未到期或未使用完的增值服务等），\n' +
    '      知了写作有权对该账号下的全部虚拟收益做清除处理，因此产生的后果由您自行承担。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      10）知了写作发现账号使用者并非账号初始注册人时，有权暂停向用户提供本服务的部分或全部服务，并有权采取进一步措施。您不应将账号或密码转让、出借、销售或以任何脱离您控制的形式交由他人使用，您对使用您的账号发生的所有活动负责，且转让、出借账号导致的任何后果均由您本人承担。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">3. 服务使用规范</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1）基于您对本服务的使用，知了写作许可您一项个人的、基于法定或约定事由可撤销的、不可转让的、非独占的和非商业的使用本服务进行内容输入并获得内容输出的权利。本协议未明示授权的其他一切权利仍由知了写作保留，您在行使该些权利前须另行获得知了写作的书面许可，同时知了写作如未行使前述任何权利，并不构成对该权利的放弃。如果您对外发布或传播本服务生成的输出，您应当：（1）主动核查输出内容的真实性、准确性，避免传播虚假信息；（2）以显著方式标明该输出内容系由人工智能生成，以向公众提示内容合成的情况；（3）避免发布和传播任何违反本协议使用规范的输出内容。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      2）用户在使用知了写作提供的上述服务时，应遵守本协议，遵守自愿、平等、公平及诚实信用原则，不利用本服务侵犯他人合法权益及谋取不正当利益，不扰乱互联网平台正常秩序。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      3）为履行法定合规要求，知了写作有权采取技术手段或人工手段对用户使用本服务的行为及信息进行审查，包括但不限于对输入和输出进行审查、建立风险过滤机制、建立违法内容特征库等。对于违规用户，知了写作有权按照本协议第八条的规定进行处置。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      4）\n' +
    '      知了写作提示您，作为本服务的使用者，您在使用上述服务时应积极承担信息安全义务。您承诺不对本服务采取以下行为：\n' +
    '    </p>\n' +
    '\n' +
    '    <p>5）通过输入，诱导生成违反相关法律法规的输出，包括但不限于：</p>\n' +
    '\n' +
    '    <p>\n' +
    '      a）反对宪法所确定的基本原则。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      b）危害国家安全，泄露国家秘密，颠覆国家政权，推翻社会主义制度，破坏国家统一。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      c）损害国家荣誉和利益。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      d）煽动民族仇恨、民族歧视，破坏民族团结。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      e）侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      f）破坏国家宗教政策，宣扬邪教和封建迷信。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      g）散布谣言、虚假有害信息，扰乱社会秩序，破坏社会稳定。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      h）宣扬淫秽、色情、赌博、暴力、凶杀、恐怖主义、极端主义或者教唆犯罪。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      i）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      j）诽谤他人，泄露他人隐私，侵害他人名誉权、肖像权、隐私权、知识产权和其他合法权益。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      k）破坏国际关系以及国际和平稳定。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      l）含有法律、行政法规禁止的其他内容的信息。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>6）通过输入，诱导生成不友善对话的输出，包括但不限于：</p>\n' +
    '\n' +
    '    <p>\n' +
    '      a）人身攻击及辱骂他人。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      b）针对以下群体发表诅咒、歧视、漠视生命尊严等性质的言论，群体包括：不同特定国籍、地域、性别、性别认同、性倾向、种族、民族、健康状况、职业、年龄、信仰、残障群体等。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      c）对他人进行诅咒、恐吓或威胁。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      d）对其他用户创作的内容直接进行贬低性的评论。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      e）对其他用户使用粗俗用语，并产生了冒犯。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      f）针对以下群体发表偏见性质的言论，群体包括：特定国籍、地域、性别、性别认同、性倾向、种族、民族、健康状况、职业、年龄、信仰、残障群体等。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>7）通过输入，恶意对抗本服务的过滤机制，包括但不限于：</p>\n' +
    '\n' +
    '    <p>\n' +
    '      a）输入难以辨识涵义影响阅读体验的字符、数字等无意义乱码。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      b）恶意对抗行为，包括但不限于使用变体、谐音等方式规避服务检测来输入违反上述4.1和4.2的言论。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>8）干扰本服务正常运行以及损害知了写作合法权益，包括但不限于：</p>\n' +
    '\n' +
    '    <p>\n' +
    '      a）利用本服务从事窃取商业秘密、窃取个人信息等违法犯罪活动。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      b）对本服务进行反向编译、反向工程或者以其他任何途径获得与本服务算法相关的任何信息。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      c）利用本服务开发与本服务存在竞争关系其他产品和服务（除非该限制违法相关法律规范）。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      d）以任何方式（包括但不限于通过任何机器人、蜘蛛、其他自动设置、设置镜像）来抓取、复制本服务及其所包含的任何内容。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      e）未经知了写作同意或未有合法正当依据，擅自去除、篡改本服务涉及的人工智能生成标识或深度合成内容显著标识。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      f）将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、发布、发送电子邮件或以其他方式传送。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      g）非法入侵本服务服务器、篡改本服务之相关代码或其他损害本服务的行为。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      h）企图规避知了写作平台的安全设置或网络系统，包括获取用户不应获取的数据，登录未经明确授权的服务器或账号，或采用运行端口扫描等方式窥探其他网络的安全措施。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      i）以导致知了写作的计算机系统或设施承受不合理负载的方式获取输出，或进行可能导致此类情况出现的行为。\n' +
    '\n' +
    '      <br>\n' +
    '\n' +
    '      j）未经知了写作授权，非法转售或对外提供知了写作生成服务，或以商业化目的使用知了写作生成服务。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      9）其他违反法律法规规定、侵犯他人以及知了写作合法权益的行为，例如实施垄断或不正当竞争行为等。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">4. 知识产权和其他权利</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1）知了写作为本服务的开发、运营主体，并对本服务的开发和运营等过程中产生的所有数据、信息、输出等享有法律法规允许范围内的全部权利，但相关权利人依照法律规定应享有权利的除外。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      2）知了写作在本服务中提供的内容（包括但不限于软件、技术、程序、代码、用户界面、网页、文字、图片、图像、音频、视频、图表、版面设计、商标、电子文档等）的知识产权（包括但不限于著作权、商标权、专利权和其他知识产权）属于知了写作所有，但相关权利人依照法律规定应享有权利的除外。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      3）您理解并承诺，您在使用本服务时的输入不侵犯任何人的知识产权、肖像权、名誉权、荣誉权、姓名权、隐私权、个人信息权益等合法权益，不涉及任何国家秘密、商业秘密、重要数据或其他可能会对国家安全或者公共利益造成不利影响的数据，否则由此产生的侵权风险和责任由您承担。知了写作由此遭受的全部直接和间接损失（包括但不限于经济、商誉、维权支出、律师费等损失）也同样由您承担。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      4）您知悉、理解并同意，如果您按照法律规定对您的输入（包括您使用本服务过程中自行上传、发布的全部内容）和/或输出享有权利的（包括但不限于知识产权、肖像权等），您使用本服务均不会造成前述合法权利的转移或减损，除非我们与您另有约定。与此同时，您理解并同意知了写作将在法律允许的范围内为实现本服务目的对您上传、发布的内容进行存储及使用（包括但不限于复制、分发、传送、公开展示、编辑等）。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">5. 个人信息保护</div>\n' +
    '\n' +
    '    <p>\n' +
    '      知了写作非常重视对用户个人信息的保护。知了写作将按照《知了写作隐私政策》的规定收集、使用、储存和分享您的个人信息。《知了写作隐私政策》为本协议不可分割的组成部分。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">6. 服务费用</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1）知了写作可能在提供本服务过程中向您收取费用，具体以届时知了写作就本服务对外公布的信息为准。如用户使用本服务中的收费项目，应按照知了写作公布的计费标准和方式支付相关费用。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      2）对于暂不收费的功能，知了写作有权根据实际情况对暂不收费的服务内容、服务期限及服务标准等进行单方变更，用户对此理解并接受前述的变更。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      3）前述修改、变更或开始收费前，知了写作将在相应服务页面进行通知或公告。如用户拒绝付费，那么用户将不能继续使用本服务中的收费功能。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">7.违约责任和处罚措施</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1）因用户违反相关法律法规或本协议约定导致的任何后果，均由用户独立承担责任、赔偿损失，与知了写作无关。若因用户使用本服务的行为给知了写作或与知了写作公司合作的其他单位面临任何投诉、举报、质询、索赔、诉讼，或者使知了写作公司或者与知了写作公司合作的其他单位因此遭受任何名誉、声誉或者财产上的损失，您应积极地采取一切可能采取的措施，以保证知了写作公司及与知了写作公司合作的其他单位免受上述索赔、诉讼的影响。同时您对知了写作公司及与知了写作公司合作的其他单位因此遭受的直接及间接经济损失负有全部的损害赔偿责任。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      2）如果用户以任何手段干扰本服务的运行或干扰其他用户对本服务的使用的，知了写作有权要求其赔偿损失（包括但不限于经济、商誉、维权支出、律师费等损失）。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      3）如果用户有上述违反“第4条\n' +
    '      服务使用规范”行为的，知了写作有权采取警示、限制功能、暂停或终止提供服务、删除违法违规信息、锁定账号等知了写作认为合理的处理措施，并保存有关记录。对处理结果存在疑议的，用户可根据9.2条之规定向我们进行申诉，我们将及时受理并进行处理和反馈。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">8.投诉、申诉处理</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1）投诉。如果您认为本服务涉及侵害您知识产权或其他权利的，或者您发现任何违法、虚假信息以及违反本协议的使用行为，您可将相关投诉材料发送至该邮箱：【xiaoxing2024@126.com 】我们会在收到您的投诉材料后进行处理，包括及时采取停止生成、停止传输、消除等合理处置措施。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      2）申诉。如果您认为我们不恰当地限制了您使用本服务的能力，您可以将相关申诉材料发送至该邮箱：【xiaoxing2024@126.com 】申诉所需的材料至少包括您的账号信息、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、账号当前状态、账号因何种使用情形被采取措施以及您希望我们采取何种措施恢复您使用本服务的能力。您提交申诉申请后，我们将向您发送回执编号。您需记录回执编号以用于进度查询。申诉结果我们将以邮件方式通知您。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>3）我们将在您提出投诉或申诉后的5个工作日内向您反馈相关情况。</p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">9.免责声明</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1）知了写作致力于提供安全、稳定、持续的服务，保障用户正常使用，但知了写作仅以“现状”、“当前功能”的状态提供本服务。知了写作不承担除法律有明确规定外的其他责任，如：不保证本服务在操作上不会中断或没有错误，不保证在现有技术水平下有能力纠正本服务的所有缺陷，亦不保证本服务能满足用户的所有要求。知了写作将在现有技术的基础上尽最大努力提供相应的安全措施以保障本服务的安全和正常运行，包括但不限于通过限制用户单次可发送的服务器请求数量。在特定时间段内用户的请求数量达到风险预警上限后，基于服务运营安全的考虑，您的账号可能会被暂停。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      2）由于可能存在的计算机病毒、网络通讯故障、系统维护等方面的因素以及可能发生的不可抗力事件或意外事件，知了写作在此明确声明对本服务不作明示或暗示的保证，包括对本服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途或满足用户的需求、使用效果等。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      3）人工智能和机器学习是快速发展的研究领域，知了写作也在不断努力改进本服务，使其更加准确、可靠、安全、可信。但限于技术特性，知了写作在尽到合理努力的前提下，仍无法完全保证您通过本服务获得的输出的合法性、真实性、准确性以及完整性。您应对本服务中的输出自行加以判断，并承担因使用内容引起的所有风险。我们提示您，除法律有明确规定外，知了写作无法也不会对前述风险而导致的任何损失或损害承担责任。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      4）本服务下的输出不代表专业意见，不能代替如法律、医疗、金融等专业领域从业人员向您解答对应疑问。相关观点也仅限于算法所触达有限数据呈现的供您参考的结果，并不代表知了写作立场。由于机器学习特性，就相同的输入可能产生不同的输出结果，请您注意甄别。您根据输出的内容所作出的任何判断或者据此作出的后续相关操作行为，以及由此带来的后果和责任均由您自行承担，请科学理性认识和依法使用本服务。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">10.法律管辖和适用</div>\n' +
    '\n' +
    '    <p>\n' +
    '      1）本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      2）本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如果协商不成，任何一方均有权将争议提交至本产品所在地有管辖权的人民法院进行诉讼解决。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">11.本协议的更新与终止</div>\n' +
    '\n' +
    '    <p>\n' +
    '      您对本协议的接受包括接受我们对本协议任何条款随时所做的任何修改。我们有权随时修改本协议，更新后的协议将通过官方网站公布等适当的方式进行公布。一旦公布即代替原协议条款，请您自行在官方网站查阅最新版协议条款。如您不接受修改后的协议，请您立即停止使用本服务。您继续使用本服务将被视为您已接受了修改后的协议。\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">12.联系方式</div>\n' +
    '\n' +
    '    <p>\n' +
    '      如果您对知了写作网页产品有任何疑问、意见或建议，或需要进行投诉或举报，请随时联系我们。您可以通过以下方式与我们取得联系：\n' +
    '    </p>\n' +
    '\n' +
    '    <p>电子邮件： xiaoxing2024@126.com </p>\n' +
    '\n' +
    '    <p>我们的客服团队将尽快回复您的问题并提供帮助。</p>\n' +
    '\n' +
    '    <div class="xunyun-xbt">13.条款生效</div>\n' +
    '\n' +
    '    <p>\n' +
    '      本服务条款自您开始使用知了写作网页产品时生效，并在您继续使用产品时持续有效。如果您不同意本条款的任何内容，或因任何原因无法遵守这些条款，请您立即停止使用本产品。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      我们保留在必要时修改、更新或补充这些条款的权利，任何修改将在本产品的相关页面上公布，并在生效前提前通知您。请您定期查阅条款，以获取最新的信息和条款内容。\n' +
    '    </p>\n' +
    '\n' +
    '    <p>\n' +
    '      感谢您选择使用知了写作网页产品，期待与您共同创造更好的使用体验。如有任何疑问或需要进一步信息，请随时联系我们。\n' +
    '    </p>\n' +
    '  \n' +
    '\n' +
    '</div>',
  privacy: '<div class="xunyun-box">\n' +
    '        <p>欢迎使用知了写作网页产品（以下简称“产品”或“本产品”）。为了向用户提供文献解读服务，本产品会收集、使用和披露一些用户信息。本隐私政策详细说明了我们如何处理您的个人信息以及您在使用本产品时的权利和选择。请仔细阅读此隐私政策，以了解我们的做法和承诺\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            一、我们如何收集和使用您的个人信息\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            我们会出于本个人信息保护规则所述的以下目的，收集和使用您的个人信息。我们理解您输入的对话信息、提交的信息反馈以及其他您向我们提供的信息中可能包含他人的个人信息，这种情况下请您务必在提供前取得他人的合法授权，避免造成他人个人信息的不当泄露。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            1.注册/登录\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            当您申请使用知了写作服务，可以登录已经注册的知了写作通用账号。当注册知了写作通用账号时，您需向我们提供以下信息：密保手机、微信号信息，并创建账号密码。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            我们可能会通过您提供的手机号码向您发送有关知了写作产品和服务的最新讯息。如果您不希望收到该讯息，可以根据讯息中提供的方式拒绝接收此类信息或者通过【xiaoxing2024@126.com 】联系我们进行处理。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            2.生成内容\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            我们生成内容旨在为您提供个性化的写作服务。生成的文章或段落是基于您提供的标题和选择项，结合Chat\n' +
    '            GPT技术以及我们自有的文献资料库，通过算法自动生成的，不包含真实个人信息。这些内容仅供您个人使用，我们承诺不会将其用于其他用途，也不会与其他用户分享。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            在生成内容的过程中，我们会严格保护您的隐私，确保您的个人信息安全。我们不会保存您的输入信息。生成的文章可能会被保存在服务器上，以便您在需要时进行下载或查阅。然而，在您使用完毕后，我们将尽快从服务器上删除相关数据，以保障您的信息隐私。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            3.客服功能\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            您在联系我们时，我们会要求您提供并使用您的手机号码、知了写作通用账号信息。为保证您的账号安全，我们提供客户服务时会使用您的手机号码、知了写作通用账号信息，以核验您的身份。当您需要我们提供与您使用过程信息相关的客服服务时，我们将会查询您的使用信息。如您拒绝提供，则无法使用客服功能。我们还会收集和使用您在使用客服功能过程中自愿提供的其他信息，以响应您的服务请求。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            4.为您提供安全保障\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            为提高您使用我们服务的安全性，我们可能使用您密保手机号码、使用记录信息用于身份验证、客户服务、安全防范、诈骗监测等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、其他使用本服务的用户、我们及社会公众的合法权益。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            5.其他用途\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            (1)\n' +
    '            我们将根据相关法律法规的要求通过技术手段对个人信息进行必要的去标识化或匿名化处理，处理后的信息将无法精确识别到特定个人信息主体。请您了解并同意，在不透露您个人信息且不违背相关法律法规的前提下，我们有权对用户数据进行分析并予以利用，包括但不限于使用技术处理后的对话信息提高知了写作对您输入内容的理解能力，以便不断改进知了写作的识别和响应的速度和质量，提高知了写作的智能性。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            (2) 当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理、去标识化等必要方式对您的信息进行脱敏展示，以保护您的信息安全。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            (3)\n' +
    '            当我们要将您的个人信息用于本个人信息保护规则未载明的其它用途时，或将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。请您理解，我们向您提供的服务将不断更新变化。如果您选择使用本个人信息保护规则中尚未列明的其他功能时，在我们收集您的个人信息前，我们会通过协议、页面提示的方式向您详细说明信息收集的目的、方式、范围并征求您的明确同意。若您不同意提供前述信息，您可能无法使用该项服务，但不影响您使用其他服务。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            6.事先征得授权同意的例外\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            请注意：在以下情形中，收集、使用个人信息无需事先征得您的授权同意：\n' +
    '            <br>\n' +
    '            (1) 与国家安全、国防安全直接相关的；\n' +
    '            <br>\n' +
    '            (2) 与公共安全、公共卫生、重大公共利益直接相关的；\n' +
    '            <br>\n' +
    '            (3) 与刑事侦查、起诉、审判和判决执行等直接有关的；\n' +
    '            <br>\n' +
    '\n' +
    '            (4) 为履行法定职责或者法定义务所必需；\n' +
    '            <br>\n' +
    '            (5) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n' +
    '            (6) 所收集的个人信息是您自行向社会公众公开的；\n' +
    '            <br>\n' +
    '            (7) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；\n' +
    '            (8) 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；\n' +
    '            <br>\n' +
    '            (9) 为订立、履行您作为一方当事人的合同所必需，或者根据您的要求向您提供服务所必需的；\n' +
    '            <br>\n' +
    '            (10) 用于维护所提供的服务的安全稳定运行所必需的，例如发现、处置服务的故障；\n' +
    '            <br>\n' +
    '            (11) 法律法规规定的其他情形。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            二、我们如何使用Cookie和同类技术\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。Cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用Cookie技术，我们能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            当您使用本服务时，我们会向您的设备发送Cookie。当您与我们提供给合作伙伴的服务（例如广告和/或推广服务，以及可能显示在其他网站上的由知了写作提供的服务功能）进行交互时，我们允许Cookie（或者其他匿名标识符）将您访问、使用产品或服务的相关信息发送至知了写作的服务器。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            我们不会将Cookie用于本个人信息保护规则所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改使用人员设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的知了写作提供的服务或功能。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            三、我们如何保存及保护您的个人信息\n' +
    '        </div>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            1.保存期限\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            我们将您在使用知了写作服务期间为您保存您的个人信息。如果您注销账号或主动删除上述信息，我们将在《个人信息保护法》、《网络安全法》、《数据安全法》等法律法规允许范围内保存您的信息。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            2.保存地域\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            您的个人信息均储存于中华人民共和国境内，我们不会向境外任何第三方提供。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            3.安全措施\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            (1) 我们会以“最小化”原则收集、使用、存储和传输用户信息，并通过相关协议和个人信息保护规则告知您相关信息的使用目的和范围。\n' +
    '            <br>\n' +
    '            (2)\n' +
    '            我们非常重视信息安全。我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。\n' +
    '            <br>\n' +
    '            (3)\n' +
    '            我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们会对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，则会追究其相关法律责任。\n' +
    '            <br>\n' +
    '            (4)\n' +
    '            我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的服务时所用的系统和通讯网络，均有可能在我们控制之外的其他环节出现安全问题。\n' +
    '            <br>\n' +
    '            (5) 根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为公司级特大安全事件，一经发生将启动公司最高级别的紧急预案，由安全部、政府关系部、法务部等多个部门组成联合应急响应小组处理。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            4.安全事件通知\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            (1) 我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。\n' +
    '            <br>\n' +
    '            (2)\n' +
    '            人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失。\n' +
    '            <br>\n' +
    '            (3)\n' +
    '            在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            四、您的选择与权利\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            按照中国相关的法律、法规、标准，我们保障您对自己的个人信息行使以下权利：\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            1.查阅、复制、转移您的个人信息\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <p>如果您希望查阅或复制您的账号的绑定信息、更改您的密码、进行账号关联、身份认证等，您可以通过账号设置执行此类操作。</p>\n' +
    '        <p>如果您希望查阅或复制您账号的问答历史，您可以在服务页面左侧查看对话历史记录以执行此类操作</p>\n' +
    '        <p>如果您无法通过上述链接查阅或复制该等个人信息或者您希望查阅、复制您在使用我们的服务过程中产生的其他个人信息，您可以通过【xiaoxing2024@126.com 】随时与我们联系。</p>\n' +
    '        <p>如果您需要转移我们收集的个人信息，您可以通过【xiaoxing2024@126.com 】随时与我们联系，我们将在符合国家相关法律法规及网信部门规定的条件下按照相关规定的要求提供转移的途径。\n' +
    '        </p>\n' +
    '\n' +
    '        <div class="xunyun-xbt">\n' +
    '            2.更正您的个人信息\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            您发现我们处理的关于您的个人信息有错误或不完整时，您有权对错误或不完整的信息作出更正或补充，您可以通过【xiaoxing2024@126.com 】随时与我们联系。为保障安全，我们将在您行使更正、补充权前对您的身份进行验证。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            3.删除您的个人信息\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            如果您希望删除您账号的生成记录，您可以记录列表执行删除操作。 在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过【xiaoxing2024@126.com 】随时与我们联系：\n' +
    '            <br>\n' +
    '            (1)如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；\n' +
    '            <br>\n' +
    '            (2)如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            4.改变您授权同意的范围\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            如您想改变授权范围，您可通过知了写作平台产品的账号设置修改授权范围，例如：解除知了写作账号与第三方账号的绑定关系。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            5.注销您的账号\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            您随时可注销此前注册的账号。您可以通过页面左下角，点击账号设置，注销您的知了写作账号。一旦您注销知了写作账号，将无法使用知了写作全线用户产品和服务，因此请您谨慎操作。为了保护您或他人的合法权益，我们会结合您对知了写作各产品和服务的使用情况判断是否支持您的注销请求。如您通过第三方账号授权登录知了写作时，需要向第三方申请注销账号。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            6.提前获知服务停止运营\n' +
    '        </div>\n' +
    '        <p>知了写作愿一直陪伴您，若因特殊原因导致知了写作或其他知了写作平台产品和服务被迫停止运营，我们将按照法律法规要求在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。\n' +
    '        </p>\n' +
    '\n' +
    '        <div class="xunyun-xbt">\n' +
    '            7.响应您的上述请求\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。通常我们会在15日内响应您的请求。\n' +
    '\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。\n' +
    '\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：\n' +
    '            <br>\n' +
    '            (1) 与我们履行法律法规规定的义务相关的；\n' +
    '            <br>\n' +
    '            (2) 与国家安全、国防安全直接相关的；\n' +
    '            <br>\n' +
    '            (3) 与公共安全、公共卫生、重大公共利益直接相关的；\n' +
    '            <br>\n' +
    '            (4) 与刑事侦查、起诉、审判和执行判决等直接相关的；\n' +
    '            <br>\n' +
    '            (5) 我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；\n' +
    '            <br>\n' +
    '            (6) 响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；\n' +
    '            <br>\n' +
    '            (7) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n' +
    '            <br>\n' +
    '            (8) 涉及商业秘密的。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            五、我们如何处理未成年人的个人信息\n' +
    '        </div>\n' +
    '        <p>知了写作非常重视对未成年人信息的保护。</p>\n' +
    '        <p>我们的网站和服务主要面向成人，我们将不满18周岁的任何人均视为未成年人。我们将根据国家相关法律法规的规定保护未成年人的个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。\n' +
    '        </p>\n' +
    '        <p>如果我们发现自己在未事先获得必要的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关个人信息。</p>\n' +
    '        <p>若您是未成年人，建议您请您的监护人仔细阅读本个人信息保护规则，并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。</p>\n' +
    '        <p>如您的监护人不同意您按照本个人信息保护规则使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。</p>\n' +
    '        <p>如果监护人发现我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过【xiaoxing2024@126.com 】联系我们，我们会设法尽快删除相关个人信息。</p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            六、个人信息保护规则的更新\n' +
    '        </div>\n' +
    '        <p>请您理解，我们可能适时修订本个人信息保护规则内容。对于会导致您在本个人信息保护规则项下权利的实质减损的重大变更，我们会于变更生效前在服务的主要曝光页面或通过站内信、电子邮件或其他合适的能触达您的方式通知您。若您不同意该等变更，您可以停止使用知了写作服务。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            本个人信息保护规则所指的重大变更包括但不限于： <br>\n' +
    '            (1) 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；\n' +
    '            <br>\n' +
    '            (2) 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；\n' +
    '            <br>\n' +
    '            (3) 您参与个人信息处理方面的权利及其行使方式发生重大变化；\n' +
    '            <br>\n' +
    '            (4) 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时； 5.个人信息安全影响评估报告表明存在高风险时。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            七、如何联系我们\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            如果您对知了写作及本个人信息保护规则有任何意见或建议，您可以通过发送邮件至【xiaoxing2024@126.com 】联系我们。\n' +
    '        </p>\n' +
    '        <div class="xunyun-xbt">\n' +
    '            八、条款生效\n' +
    '        </div>\n' +
    '        <p>\n' +
    '            本隐私政策自您开始使用知了写作网页产品时生效，并在您继续使用产品时持续有效。如果您不同意本条款的任何内容，或因任何原因无法遵守这些条款，请您立即停止使用本产品。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            我们保留在必要时修改、更新或补充这些条款的权利，任何修改将在本产品的相关页面上公布，并在生效前提前通知您。请您定期查阅条款，以获取最新的信息和条款内容。\n' +
    '        </p>\n' +
    '        <p>\n' +
    '            感谢您选择使用知了写作网页产品，期待与您共同创造更好的使用体验。如有任何疑问或需要进一步信息，请随时联系我们。\n' +
    '        </p>\n' +
    '        附录1：定义\n' +
    '        <br>\n' +
    '        本个人信息保护规则中使用的特定词语，具有如下含义：\n' +
    '        <br>\n' +
    '        3.“知了写作”，指知了写作依托 ChatGPT的技术和推出的生成类人工智能问答类、绘图类技术服务。\n' +
    '        <br>\n' +
    '        4.“知了写作平台”，指知了写作网站（https://www.xianduai.com）及相关插件\n' +
    '        <br>\n' +
    '        5.“个人信息”，指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于敏感个人信息。\n' +
    '        <br>\n' +
    '        6.“个人信息主体”，指个人信息所关联的自然人。<br>\n' +
    '        7.“敏感个人信息”，指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。敏感个人信息包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满14周岁未成年人的个人信息。\n' +
    '        <br>\n' +
    '        8.“去标识化”，指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。\n' +
    '        <br>\n' +
    '        9.“匿名化”，指个人信息经过处理无法识别特定自然人且不能复原的过程。\n' +
    '        <br>\n' +
    '        10.“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本个人信息保护规则之目的，不包含香港特别行政区、澳门特别行政区和台湾地区。\n' +
    '    </div>'
}

export default agreement
