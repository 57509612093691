<template>
  <div class="form-box">
    <div class="form-title">
      <h1>支付成功</h1>
    </div>
    <div class="form-list">

      <div class="form-search">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" class="demo-ruleForm" @submit.native.prevent>
          <el-form-item label="输入订单号" prop="orderId">
            <el-input placeholder="请输入支付时的订单编号，可在支付宝的订单详情中查找"
                      v-model="ruleForm.orderId"
                      type="text"
                      class="input-with-select">
              <el-button slot="append" icon="el-icon-search" @click="submitForm('ruleForm')">查询</el-button>
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="form-success">
        <img src="@/assets/images/pay-success.png" alt="" />
        <p :class="myOrder.payState === 0 ? 'tit2' : 'tit'">
          {{payStatus[myOrder.payState]}}
        </p>
        <div class="timer-item">将在 <span>{{ countdown }}</span>秒 后自动跳转至查询页面</div>
        <p class="tip2">查询方法如下所示:</p>
      </div>

      <OrderExamples></OrderExamples>

    </div>
  </div>
</template>

<script>
  import CommonApi from "@/api/common"
  import OrderExamples from '@/components/OrderExamples/OrderExamples.vue'
	export default {
    components:{
      OrderExamples
    },
		data() {
			return {
        ruleForm: {
          orderId: ''
        },
        rules: {
          orderId: [
            { required: true, message: '请输入订单号', trigger: 'blur' },
          ]
        },
        myOrder: '',
        payStatus: ['等待支付','支付成功'],
        countdown: 0, // 倒计时跳转
        timer: null,  //定时器
			}
		},
		created() {
    },
    beforeDestroy() {
      //清除定时器
      this.clearCountdown()
    },
    mounted() {
      // console.log(this.$route.query)
      let orderInfo = this.$route.query
      if(orderInfo.info){
        this.postQueryOrderDetail(orderInfo.info)
      }else{
        this.$router.go(-1)
      }
    },
    beforeRouteLeave(to, from, next){
      this.inputRadioGroup(false)
      next();
    },
		methods: {
      inputRadioGroup(type){
        this.$emit('getMajorValue',type)
      },
      submitForm(formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            localStorage.setItem('currentIndex',120)
            this.$router.push({path: _this.orderQueryUrl + '?info='+ _this.ruleForm.orderId})
          } else {
            this.$notify({
              title: '提示',
              message: '校验不通过，请稍确认填写后再试',
              type: 'warning'
            });
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 查询订单信息
      postQueryOrderDetail(id){
        let _this = this
        // console.log(id)
        CommonApi.queryOrderDetail(id).then(res => {
          _this.myOrder = res.data
          _this.ruleForm.orderId = _this.myOrder.orderNo
          // 解析专业版或标准版
          if(_this.myOrder.extParam){
            _this.myOrder.extParam = JSON.parse(_this.myOrder.extParam)
            _this.inputRadioGroup(_this.myOrder.extParam.modelCode === 2)
          }
          // 启动倒计时
          _this.startCountdown()
        }).catch(() => {
        })
      },
      // 开始倒计时
      startCountdown() {
        if (this.countdown === 0) {
          // 初始化倒计时
          this.countdown = 3;
          // 设置定时器
          this.timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              // 倒计时结束，调用方法
              this.countdownEnded();
              // 清除定时器
              this.clearCountdown();
            }
          }, 1000); // 每秒执行一次
        }
      },
      // 倒计时结束后的方法
      countdownEnded() {
        let _this = this
        // console.log('倒计时结束');
        // 这里可以添加你需要在倒计时结束后执行的方法
        _this.$router.push({path: _this.orderQueryUrl + '?info='+ _this.ruleForm.orderId})
      },
      // 清除定时器
      clearCountdown() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
          this.countdown = 0; // 可选：将倒计时重置为0
        }
      },
    }
	}
</script>

<style lang="less" scoped>
  .form-box{
    position: relative;
    width: 100%;

    .form-title {
      width: 100%;
      height: 76px;
      //box-shadow: 0 2px 5px rgba(0, 85, 255, 0.1);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      h1 {
        position: relative;
        z-index: 1;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }
      h1::before {
        content: '';
        z-index: -1;
        position: absolute;
        bottom: -2px;
        left: 50%;
        width: 104px;
        height: 12px;
        margin-left: -52px;
        background-color: rgba(42, 171, 255, 0.3);
      }
    }
    .form-list{
      //width: 100%;
      //height: 90%;
      //overflow: auto;
      //margin: 0 auto;
      //padding: 30px 10% 10px 10%;
      width: 80%;
      max-width: 1000px;
      margin: 0 auto;
      padding: 10px 0;
      .form-search{
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.06) 0 2px 8px 1px;
        border-radius: 8px;
        border: 1px solid rgb(228, 229, 231);
        padding: 20px 30px;
        .el-form-item{
          margin: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          /deep/ .el-form-item__content{
            flex: 1;
            .el-button{
              border-radius: 0 3px 3px 0;
              color: #ffffff;
              background-color: #409EFF;
            }
          }
        }
      }
      .form-success{
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        img{
          width: 120px;
          height: 120px;
        }
        p{
          line-height: 30px;
          font-size: 14px;
        }
        p.tit{
          font-size: 18px;
          font-weight: bold;
          color: #36b7e6;
        }
        p.tit2{
          font-size: 18px;
          font-weight: bold;
          color: #E6A23C;
        }
        p.tip{
          span{
            font-size: 16px;
            color: #36b7e6;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .timer-item{
          span{
            margin: 0 10px;
            font-size: 32px;
            color: #e31c1c;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 8px!important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #e5e5e5;
    width: 8px!important;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }

  @media screen and (max-width: 900px) {
    .form-box{
      height: 100%;
      .h5-show{
        display: none;
      }
      .form-title{
        height: 50px;
        h1{
          font-size: 18px;
        }
      }
      .form-list{
        width: 100%;
        padding: 15px;
        margin-top: 10px;
        background-color: rgba(255,255,255,0.7);
        box-shadow: 0 2px 5px rgba(0, 85, 255, 0.1);
        border-radius: 10px;
        .form-search{
          background: none;
          box-shadow: none;
          border-radius: 8px;
          border: 0;
          padding: 0;
          .el-form-item{
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            /deep/ .el-form-item__content{
              .el-input__inner{
                font-size: 12px;
              }
              .el-button{
                width: 70px;
                height: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                i{
                  font-size: 12px !important;
                }
                span{
                  font-size: 12px !important;
                }
              }
            }
          }
        }
        .form-success{
          img{
            width: 60px;
            height: 60px;
          }
          .timer-item{
            font-size: 12px;
            span{
              font-size: 24px;
            }
          }
          .tip2{
            font-size: 12px;
          }
        }
        .el-form-item{
          margin-bottom: 10px;
        }
        .radio-pc-show{
          display: none;
        }
        .radio-h5-show{
          display: block;
        }
      }
    }
  }
</style>
